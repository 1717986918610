import React from "react"
import { Navigate } from "react-router"

import { Center, Flex, Spinner } from "@chakra-ui/react"
import { Heading } from "DesignSystem/components"
import { UserCrowdLogo } from "UserCrowd/components/UserCrowdNavbar/UserCrowdNavbar"
import { ROUTES } from "UserCrowd/views/routes"
import { Helmet } from "react-helmet"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { TestersPhoneForm } from "./PhoneForm"

export const PhoneRoute: React.FC = () => {
  const { data } = useCurrentPanelistInfo({})

  if (!data) {
    return <Spinner />
  }

  if (data.phone_number !== null) {
    return <Navigate to={ROUTES.NOTIFICATION_PREFERENCES.path} />
  }

  return (
    // The magic 130px is the vertical padding on the Root route element in UserCrowd.tsx which
    // assumes that every page will have the navbar. We should refactor this at some point.
    // (The bottom padding is added to balance it out vertically)
    <Center minH="calc(100dvh - 130px)" pb="70px">
      <Helmet title="Verify your phone number" />

      <Flex flexDirection="column" gap={10}>
        <UserCrowdLogo />

        <Heading
          as="h2"
          color="ds.text.default"
          textStyle="ds.display.primary"
          textAlign="center"
        >
          Verify your phone number
        </Heading>

        <TestersPhoneForm />
      </Flex>
    </Center>
  )
}
