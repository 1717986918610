import {
  Box,
  Flex,
  Grid,
  GridItem,
  Image,
  ImageProps,
  Link,
} from "@chakra-ui/react"
import Constants from "Constants/shared.json"
import { Button } from "DesignSystem/components"
import UserCrowdLogoImageSrc from "Images/usercrowd-logo.png"
import { ArrowLeftOutlineIcon } from "Shared/icons/untitled-ui/ArrowLeftOutlineIcon"
import { HelpCircleOutlineIcon } from "Shared/icons/untitled-ui/HelpCircleOutlineIcon"
import { ROUTES } from "UserCrowd/views/routes"
import React from "react"
import { Link as RouterLink } from "react-router-dom"
import { SettingsMenu } from "./SettingsMenu"

const gridItemStyles = {
  display: "flex",
  alignItems: "center",
}

type Props = {
  variant: "dashboard" | "inner-page" | "onboarding" | "signed-out" | "blank"
}

export const UserCrowdNavbar: React.FC<Props> = ({ variant }) => {
  return (
    <Box pos="absolute" inset={0} bg="white" h={14} shadow="ds.overflow">
      <Grid
        w="full"
        h="full"
        gridTemplateAreas={{
          base: `"left right"`,
          md: `"left center right"`,
        }}
        gridTemplateColumns={{
          base: `auto auto`,
          md: `13.5rem 1fr auto`,
        }}
        gap={[4, null, 0]}
        gridAutoFlow="column"
        gridRow="1"
      >
        <GridItem area="left" pl={[4, 8]} {...gridItemStyles}>
          {variant === "inner-page" ? <BackToDashboard /> : <UserCrowdLogo />}
        </GridItem>
        <GridItem
          area="center"
          {...gridItemStyles}
          display={{
            base: "none",
            md: "flex",
          }}
        />
        <GridItem
          area="right"
          pr={[4, 8]}
          {...gridItemStyles}
          justifyContent="flex-end"
        >
          <Flex gap={[3, 2]}>
            {variant === "blank" ? null : variant === "signed-out" ? (
              <SignInButton />
            ) : (
              <DashboardActions variant={variant} />
            )}
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  )
}

export const UserCrowdLogo: React.FC = () => (
  <UserCrowdLogoImage objectFit="contain" />
)

const UserCrowdLogoImage: React.FC<ImageProps> = (chakraProps) => (
  <Image
    h="30px"
    transform="translateY(-1px)"
    src={UserCrowdLogoImageSrc}
    {...chakraProps}
  />
)

const SignInButton: React.FC = () => (
  <Button
    variant="secondary"
    size={["compact", "default"]}
    as={Link}
    href={Constants.PANELIST_SIGN_IN}
  >
    Sign in
  </Button>
)

const DashboardActions: React.FC<Props> = ({ variant }) => (
  <>
    <Button
      variant="secondary"
      size={["compact", "default"]}
      leftIcon={<HelpCircleOutlineIcon />}
      as={Link}
      href={Constants.PANELIST_HELP_CENTER_DOMAIN}
      isExternal
    >
      Help
    </Button>
    <SettingsMenu isOnboarding={variant === "onboarding"} />
  </>
)

const BackToDashboard: React.FC = () => (
  <Button
    variant="secondary"
    size={["compact", "default"]}
    leftIcon={<ArrowLeftOutlineIcon />}
    as={RouterLink}
    to={ROUTES.DASHBOARD.path}
  >
    Dashboard
  </Button>
)
