import { Box, Flex, Grid, Heading, Text } from "@chakra-ui/react"
import { sample } from "lodash"
import React, { useState } from "react"
import { Helmet } from "react-helmet"

import {
  AssignmentList,
  AssignmentsHeading,
} from "UserCrowd/components/AssignmentList/AssignmentList"
import { TestersHeader } from "UserCrowd/components/TestersHeader"
import { Outlet } from "react-router"
import { useCurrentPanelistInfo } from "~/api/generated/usabilityhub-components"
import { PracticeTestCard } from "./PracticeTestCard"
import { ProfileCompletionBanner } from "./ProfileCompletionBanner/ProfileCompletionBanner"
import { TestersMetrics } from "./TestersMetrics"
import { UpcomingBookingList } from "./UpcomingBookingList"
import { testerGuidelines } from "./testerGuidelines"

export const DashboardRoute: React.FC = () => {
  const [guideline] = useState(() => sample(testerGuidelines))

  const { data: user } = useCurrentPanelistInfo(
    {},
    { cacheTime: Infinity, staleTime: Infinity }
  )

  const currentHour = new Date().getHours()
  let greeting: string
  if (currentHour >= 4 && currentHour < 12) {
    greeting = "Morning"
  } else if (currentHour < 18) {
    greeting = "Afternoon"
  } else {
    greeting = "Evening"
  }

  return (
    <Flex direction="column">
      <Helmet>
        <title>Dashboard • UserCrowd</title>
      </Helmet>

      {/* The dashboard renders some modals on their own nested routes. See UserCrowd.tsx for more info. */}
      <Outlet />

      <TestersHeader
        heading={
          user ? `${greeting}, ${user.preferred_name ?? user.name}` : greeting
        }
        showBackButton={false}
      />
      <TestersMetrics />
      <ProfileCompletionBanner mt={4} />

      <Grid
        templateColumns={["1fr", null, "2fr 1fr"]}
        gap={[0, null, 8]}
        mt={4}
      >
        <Box>
          <AssignmentsHeading />

          {user?.practice_test_required && <PracticeTestCard />}

          <AssignmentList />
        </Box>

        <Flex direction="column" gap={4} mt={4}>
          <UpcomingBookingList />

          {guideline && (
            <Box>
              <Heading fontSize="lg" fontWeight="medium" mb={3}>
                {guideline.heading}
              </Heading>

              {guideline.guideline.map((paragraph) => (
                <Text key={paragraph} fontSize="16px" color="text.secondary">
                  {paragraph}
                </Text>
              ))}
            </Box>
          )}
        </Flex>
      </Grid>
    </Flex>
  )
}
